var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { color, compose, fontSize, fontWeight, lineHeight, space, textAlign } from 'styled-system';
import { textDecoration, whiteSpace } from '../theme/system-utilities';
// ⚠️ Do not export this component. Select and use specific Typography component instead.
var BaseTypography = styled.div(compose(fontSize, lineHeight, fontWeight, color, space, textAlign, whiteSpace), textDecoration);
// Default colors
var textStrongColor = 'sys.neutral.text.strong';
var textDefaultColor = 'sys.neutral.text.default';
/* =============================================================== */
/* ========================== Headlines ========================== */
var HeadlineH1 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(BaseTypography, __assign({ fontSize: ['fs_28', 'fs_32', 'fs_36', 'fs_40'], lineHeight: ['lh_32', 'lh_36', 'lh_40', 'lh_44'], fontWeight: "bold", color: textStrongColor }, props), children));
};
var HeadlineH2 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(BaseTypography, __assign({ fontSize: ['fs_24', 'fs_28', 'fs_32', 'fs_36'], lineHeight: ['lh_28', 'lh_32', 'lh_36', 'lh_40'], fontWeight: "bold", color: textStrongColor }, props), children));
};
var HeadlineH3 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(BaseTypography, __assign({ fontSize: ['fs_22', 'fs_24', 'fs_28', 'fs_32'], lineHeight: ['lh_28', null, 'lh_32', 'lh_36'], fontWeight: "bold", color: textStrongColor }, props), children));
};
var HeadlineH4 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(BaseTypography, __assign({ fontSize: ['fs_20', 'fs_22', 'fs_24', 'fs_28'], lineHeight: ['lh_24', 'lh_28', null, 'lh_32'], fontWeight: "bold", color: textStrongColor }, props), children));
};
var HeadlineH5 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(BaseTypography, __assign({ fontSize: ['fs_18', 'fs_20', 'fs_22', 'fs_24'], lineHeight: ['lh_22', 'lh_24', 'lh_28'], fontWeight: "bold", color: textStrongColor }, props), children));
};
var HeadlineH6 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(BaseTypography, __assign({ fontSize: ['fs_18', null, null, 'fs_20'], lineHeight: ['lh_22', null, null, 'lh_24'], fontWeight: "bold", color: textStrongColor }, props), children));
};
var HeadlineH7 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(BaseTypography, __assign({ fontSize: ['fs_16', 'fs_18', null, 'fs_20'], lineHeight: ['lh_20', 'lh_22', null, 'lh_24'], fontWeight: "bold", color: textStrongColor }, props), children));
};
var HeadlineH8 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(BaseTypography, __assign({ fontSize: ['fs_16', null, null, 'fs_18'], lineHeight: ['lh_20', null, null, 'lh_22'], fontWeight: "bold", color: textStrongColor }, props), children));
};
var HeadlineH9 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(BaseTypography, __assign({ fontSize: ['fs_14', null, null, 'fs_16'], lineHeight: ['lh_18', null, null, 'lh_20'], fontWeight: "bold", color: textStrongColor }, props), children));
};
/* =============================================================== */
/* ========================== Subtitles ========================== */
var SubtitleST1 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(BaseTypography, __assign({ fontSize: ['fs_20', 'fs_22', 'fs_24', 'fs_28'], lineHeight: ['lh_24', 'lh_28', null, 'lh_32'], color: textStrongColor }, props), children));
};
var SubtitleST2 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(BaseTypography, __assign({ fontSize: ['fs_18', 'fs_20', 'fs_22', 'fs_24'], lineHeight: ['lh_24', null, 'lh_28'], color: textStrongColor }, props), children));
};
var SubtitleST3 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(BaseTypography, __assign({ fontSize: ['fs_16', null, null, 'fs_18'], lineHeight: ['lh_20', null, null, 'lh_22'], color: textStrongColor }, props), children));
};
var SubtitleST4 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(BaseTypography, __assign({ fontSize: ['fs_14', null, null, 'fs_16'], lineHeight: ['lh_18', null, null, 'lh_20'], color: textStrongColor }, props), children));
};
/* =============================================================== */
/* ============================= Body ============================ */
var BodyRegularBR1 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(BaseTypography, __assign({ fontSize: ['fs_16', null, null, 'fs_18'], lineHeight: ['lh_24', null, null, 'lh_28'], color: textDefaultColor }, props), children));
};
var BodyRegularBR2 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(BaseTypography, __assign({ fontSize: ['fs_14', null, null, 'fs_16'], lineHeight: ['lh_20', null, null, 'lh_24'], color: textDefaultColor }, props), children));
};
var BodyBoldBB1 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(BodyRegularBR1, __assign({ fontWeight: "bold" }, props), children));
};
var BodyBoldBB2 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(BodyRegularBR2, __assign({ fontWeight: "bold" }, props), children));
};
var BodyStrikeST1 = styled(BodyRegularBR1)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-decoration: line-through;\n"], ["\n  text-decoration: line-through;\n"])));
var BodyStrikeST2 = styled(BodyRegularBR2)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-decoration: line-through;\n"], ["\n  text-decoration: line-through;\n"])));
/* =============================================================== */
/* ============================ Links ============================ */
var LinkRegularLR1 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(BaseTypography, __assign({ fontSize: ['fs_16', null, null, 'fs_18'], lineHeight: ['lh_24', null, null, 'lh_28'], color: textStrongColor }, props), children));
};
var LinkRegularLR2 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(BaseTypography, __assign({ fontSize: ['fs_14', null, null, 'fs_16'], lineHeight: ['lh_20', null, null, 'lh_24'], color: textStrongColor }, props), children));
};
var LinkRegularLR3 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(BaseTypography, __assign({ fontSize: ['fs_12', null, null, 'fs_14'], lineHeight: ['lh_16', null, null, 'lh_20'], color: textStrongColor }, props), children));
};
var LinkRegularLR4 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(BaseTypography, __assign({ fontSize: ['fs_10', null, null, 'fs_12'], lineHeight: ['lh_14', null, null, 'lh_16'], color: textStrongColor }, props), children));
};
var LinkBoldLB1 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(LinkRegularLR1, __assign({ fontWeight: "bold" }, props), children));
};
var LinkBoldLB2 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(LinkRegularLR2, __assign({ fontWeight: "bold" }, props), children));
};
var LinkBoldLB3 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(LinkRegularLR3, __assign({ fontWeight: "bold" }, props), children));
};
var LinkBoldLB4 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(LinkRegularLR4, __assign({ fontWeight: "bold" }, props), children));
};
/* =============================================================== */
/* ========================== Captions =========================== */
var CaptionRegularCR1 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(BaseTypography, __assign({ fontSize: ['fs_12', null, null, 'fs_14'], lineHeight: ['lh_16', null, null, 'lh_20'], color: textDefaultColor }, props), children));
};
var CaptionRegularCR2 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(BaseTypography, __assign({ fontSize: ['fs_10', null, null, 'fs_12'], lineHeight: ['lh_14', null, null, 'lh_16'], color: textDefaultColor }, props), children));
};
var CaptionBoldCB1 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(CaptionRegularCR1, __assign({ fontWeight: "bold" }, props), children));
};
var CaptionBoldCB2 = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(CaptionRegularCR2, __assign({ fontWeight: "bold" }, props), children));
};
var CaptionStrikeCS1 = styled(CaptionRegularCR1)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-decoration: line-through;\n"], ["\n  text-decoration: line-through;\n"])));
var CaptionStrikeCS2 = styled(CaptionRegularCR2)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  text-decoration: line-through;\n"], ["\n  text-decoration: line-through;\n"])));
export { HeadlineH1, HeadlineH2, HeadlineH3, HeadlineH4, HeadlineH5, HeadlineH6, HeadlineH7, HeadlineH8, HeadlineH9, SubtitleST1, SubtitleST2, SubtitleST3, SubtitleST4, BodyRegularBR1, BodyRegularBR2, BodyBoldBB1, BodyBoldBB2, BodyStrikeST1, BodyStrikeST2, LinkRegularLR1, LinkRegularLR2, LinkRegularLR3, LinkRegularLR4, LinkBoldLB1, LinkBoldLB2, LinkBoldLB3, LinkBoldLB4, CaptionRegularCR1, CaptionRegularCR2, CaptionBoldCB1, CaptionBoldCB2, CaptionStrikeCS1, CaptionStrikeCS2, };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
